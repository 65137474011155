import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';
import { Markdown } from '../HoosatUI/src/Markdown/Markdown';

import "./Home.css";
import { ContactForm } from '../Components/Landing/ContactForm/ContactForm';
import { ElevatorPitch } from '../Components/Landing/ElevatorPitch/ElevatorPitch';
import { TheStack } from '../Components/Landing/TheStack/TheStack';
import { AboutUs } from '../Components/Landing/AboutUs/AboutUs';

import { Icon } from '../HoosatUI';

/**
 * Represents the Home component of the Hoosat SSR template.
 * This component displays the home page content with a title, description,
 * and a link to navigate to the About page.
 *
 * @returns {JSX.Element} The React component representing the Home page.
 */
export const Home = (): JSX.Element => {
  const [ t, i18n ] = useTranslation();
  const pageTitle = 'Hoosat Oy';
  const pageDescription = 'Teemme räätälöityä ohjelmistokehitystä ja verkkomarkkinointia sinun tarpeidesi mukaan. Ota yhteyttä! +358 44 937 5656 myynti@hoosat.fi';

  


  return (
      <main>
        {/* The Helmet component sets the title and meta tags for SEO */}
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content="https://hoosat.fi/logo512.png" />
          <meta property="og:url" content="https://hoosat.fi/" />
        </Helmet>
        <section className="landing-opening">
        <svg
            width="210mm"
            height="297mm"
            viewBox="0 0 210 297"
            version="1.1"
            id="hoosat-home-svg">
            <g>
              <path
                style={{ fill: "#116466", fillOpacity: 1, stroke: "#2c3531", strokeWidth: 1.2227, strokeOpacity: 1}}
                d="m 83.40858,34.080995 -69.65161,33.080649 -0.605684,46.615906 24.193213,-10.41578 -0.373585,121.60071 -24.039731,-9.87177 0.757142,49.71917 95.272375,-0.80442 -0.13875,-49.22971 -25.783558,10.35742 -0.168549,-36.4689 61.417187,0.31475 c 0,0 -1.76045,74.8139 0.0538,74.85845 l 53.03793,-0.25675 -0.95181,-229.453004 -53.46517,-0.003 0.1082,76.215034 -59.553419,0.0112 z m 8.325061,88.869725 c 8.932649,0.0216 17.529469,9.20299 23.548179,14.25654 6.02081,-4.94025 14.36155,-14.04226 23.18688,-14.05073 17.60808,-0.005 31.88775,10.91932 31.89556,24.40041 0.10878,12.91661 -15.53154,24.34832 -31.88142,24.42225 -8.95286,-0.005 -17.35753,-8.19443 -23.39343,-13.25687 -6.00791,4.92431 -14.54036,13.03165 -23.341638,13.05105 -17.607788,0.002 -31.884153,-10.9244 -31.888474,-24.40525 -0.0044,-13.48141 14.265883,-24.4132 31.874343,-24.4174 z" />
              <ellipse
                style={{fill: "none", fillOpacity: 1, strokeWidth: 0.264583 }}
                ry="5.6622138"
                rx="5.6784978"
                cy="107.5882"
                cx="65.427773" />
            </g>
          </svg>
          <h1>{t("landing.opening.h1")}</h1>
          <p>{t("landing.opening.p")}</p>
          <p><a href="https://network.hoosat.fi">For the Hoosat Network, click here.</a></p>
          <Markdown className="npx" markdown={t("landing.opening.npx-command")} />
          <ScrollLink
            href='#landing-about-us'
            to="landing-about-us"
            smooth={true}
            duration={1200}
            aria-label="Scroll down to the about us."
            className="scroll-down-button">
            <Icon className="scroll-down-button-icon" name={'arrow-thick-down'}></Icon>
          </ScrollLink>
        </section>
        <AboutUs  className={'landing-about-us'} nextElement={'landing-the-stack'} nextDirection={'down'}></AboutUs>
        <TheStack className={'landing-the-stack'} nextElement={'landing-elevator'} nextDirection={'down'}></TheStack>
        <ElevatorPitch className={'landing-elevator'} nextElement={'landing-contact-form'} nextDirection={'down'} ></ElevatorPitch>
        <ContactForm className={"landing-contact-form"} nextElement={'landing-opening'} nextDirection={'up'}></ContactForm>
      </main>
  );
};
